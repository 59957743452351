import { UNITS } from '../../utils/units.enum';
/* eslint-disable */
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .factory('ioFactory', ioFactory);

  function ioFactory() {
    var op = {
      encodeInputA8: encodeInputA8, //COD to INT
      encodeDigitalInput8: encodeDigitalInput8,
      encodeOutputA8: encodeOutputA8,
      encodeDigitalOutput8: encodeDigitalOutput8,

      decodeInput8: decodeInput8, //INT to COD Digital input
      decodeOutput8: decodeOutput8, //Digital output
      decodeInputA8: decodeInputA8, //Analog input
      decodeOutputA8: decodeOutputA8, //Analog output

      encodeDigitalOutput5: encodeDigitalOutput5,
      encodeDigitalInput5: encodeDigitalInput5,
      encodeAnalogOutput5: encodeAnalogOutput5,
      encodeAnalogInput5: encodeAnalogInput5,

      decodeDigitalInput5: decodeDigitalInput5,
      decodeDigitalOutput5: decodeDigitalOutput5,
      decodeAnalogInput5: decodeAnalogInput5,
    };

    var INTERNES_EA_A25 = 3;
    var INTERNES_EA_AB = 4;
    var INTERNES_EA_A55 = 3;
    var INTERNES_EA_A45 = 2;

    var MAX_MAM = 120;
    var MAX_MAR433 = 60;
    var MAX_MODBUS = 32;
    var MAX_EXPANSIOBASE = 15;

    var MAX_ME_ENT_DIG = 2;
    var MAX_MR_ENT_DIG = 8;
    var MAX_MR16_ENT_DIG = 16;
    var MAX_MM_ENT_DIG = 2;
    var MAX_MAM_ENT_DIG = 10;
    var MAX_MAR24_ENT_DIG = 16;
    var MAX_MAR433_ENT_DIG = 16;
    var MAX_AGROBEE_ENT_DIG = 9;
    var MAX_VIRTUAL_ENT_DIG = 9;
    var MAX_AGROBEEL_ENT_DIG = 9;
    var MAX_MODBUS_ENT_DIG = 15;
    var MAX_EXPANSIOBASE_ENT_DIG = 12;
    var MAX_BASE_ENT_ANA = 16;
    var MAX_ME_ENT_ANA = 4;
    var MAX_MR_ENT_ANA = 2;
    var MAX_MR16_ENT_ANA = 2;
    var MAX_SMS_ENT_ANA = 8;
    var MAX_MISIS_ENT_ANA = 8;
    var MAX_MAM_ENT_ANA = 2;
    var MAX_MAR24_ENT_ANA = 2;
    var MAX_MAR433_ENT_ANA = 2;
    var MAX_AGROBEE_ENT_ANA = 16;
    var MAX_VIRTUAL_ENT_ANA = 9;
    var MAX_DAVIS_ENT_ANA = 33;
    var MAX_AGROBEEL_ENT_ANA = 16;
    var MAX_SDI12_ENT_ANA = 24;
    var MAX_MODBUS_ENT_ANA = 15;
    var MAX_EXPANSIOBASE_ENT_ANA = 16;
    var MAX_ME_SOR_DIG = 5;
    var MAX_MR_SOR_DIG = 8;
    var MAX_MR16_SOR_DIG = 16;
    var MAX_MM_SOR_DIG = 1;
    var MAX_RITOWA_SOR_DIG = 15;
    var MAX_MAM_SOR_DIG = 8;
    var MAX_MAR24_SOR_DIG = 16;
    var MAX_MAR433_SOR_DIG = 16;
    var MAX_MODBUS_SOR_DIG = 15;
    var MAX_EXPANSIOBASE_SOR_DIG = 99;

    var MAX_AGROBEE = 16;
    var MAX_AGROBEE_SOR_DIG = 9;
    var MAX_AGROBEE_SOR_ANA = 2;
    var MAX_AGROBEE_SOR_ANA = 2;
    var MAX_MODBUS_SOR_ANA = 15;
    var MAX_EXPANSIOBASE_SOR_ANA = 10;
    var MAX_AGROBEEL = 20;
    var MAX_AGROBEEL_SOR_DIG = 9;
    var MAX_SDI12 = 8;
    var MAX_MAR433 = 60;

    var INTERNES_ED_A25 = 2; // 2 entrades digitals internes al 2500
    var INTERNES_EA_A25 = 3; // 3 entrades analogiques internes al 2500
    var BASE_C154_EA_A25 = 4; // 3 entrades analogiques internes al 2500

    var INTERNES_EA_ABEE = 4; // 4 entrades analogiques internes dels AgroBees
    var INTERNES_EA_ABEEL = 3; // 3 entrades analogiques internes dels AgroBeeLs

    var INTERNES_SD_AB = 1; // 1 sortida digital interna al Bit
    var INTERNES_EA_AB = 4; // 4 entrades analogiques internes al Bit

    var INTERNES_ED_A55 = 1; // nº d'entrades digitals especial a la base (internes)
    var INTERNES_EA_A55 = 3; // nº d'entrades analògiques especials a la base (internes)
    var BASE_C154_EA_A55 = 4; // 4 entrades analògiques del micro (c154)
    var BASE_C154_SD_A55 = 10; // 10 sortides digitals del micro (c154)

    // final de les entrades analògiques
    var FI_EA_ME = 80; // mòdul d'expansió
    var FI_EA_AR868 = 464; // Agrònic Ràdio 868
    var FI_EA_SMS = 472; // missatges SMS
    var FI_EA_MI = 504; // MicroIsis
    var FI_EA_MET = 528; // uMetos
    var FI_EA_AM120 = 1008; // Agrònic Monocable 120
    var FI_EA_AR16 = 1392; // Agrònic Ràdio 868-16
    var FI_EA_AR24 = 2112; // Agrònic Ràdio 2.4
    var FI_EA_AR433 = 2472; // Agrònic Ràdio 433
    var FI_EA_AM120_2 = 2952; // 2ª entrada analògica dels MAM
    var FI_EA_DAVIS = 2986; // Estació meteorològica Davis
    var FI_EA_AR433 = 2472; // Agrònic Radio 433

    //final sortides digitals

    var FI_SD_ME = 200; // mòdul d'expansió
    var FI_SD_AM64 = 1992; // Agrònic Monocable 64
    var FI_SD_AR868 = 1736; // Agrònic Ràdio 868
    var FI_SD_AM120 = 6282; // Agrònic Monocable 120
    var FI_SD_RIT = 2442; // ràdio Itowa
    var FI_SD_AR24 = 15114; // Agrònic Ràdio 2.4
    var FI_SD_AR16 = 9354; // Agrònic Ràdio 868-16
    var FI_SD_AR433 = 17994; // Agrònic Ràdio 433

    //final entrades digitals
    var FI_ED_AM120 = 6912; // Agrònic Monocable 120
    var FI_ED_AR433 = 18624; // Agrònic Radio 433

    return op;

    function MAXAGROBEEL(type) {
      if (type === UNITS.A_4500) return 2;
      else return 1;
    }

    function MAXEAM(type) {
      switch (type) {
        case UNITS.A_4000:
        case UNITS.A_7000:
          return 1;
        case UNITS.A_4500:
          return 2;
        default:
          return 0;
      }
    }
    function MAXEAR433(type) {
      switch (type) {
        case UNITS.A_4000:
        case UNITS.A_7000:
          return 1;
        case UNITS.A_4500:
          return 2;
        default:
          return 0;
      }
    }

    function FI_SD_AM120(type) {
      switch (type) {
        case UNITS.A_4500:
          return FI_SD_MODBUS(type) + MAXEAM(type) * MAX_MAM * MAX_MAM_SOR_DIG;
        default:
          return 6282;
      }
    }

    function FI_EA_BASE(type) {
      if (type === 0) return 16;
      else if (type === UNITS.A_4000) return 16;
      else if (type === 1) return 16;
      else if (type === UNITS.A_2500) return 2 + INTERNES_EA_A25; // (3 especials)
      else if (type === UNITS.A_BIT) return 13 + INTERNES_EA_AB; // (4 especials)
      else if (type === UNITS.A_7000) return 16;
      else if (type === UNITS.A_5500) return 12 + INTERNES_EA_A55; // (3 especials);
      else if (type === UNITS.A_4500) return 16 + INTERNES_EA_A45; // (2 especials, SENSORS ESPECIFICS);
      else return 0;
    }

    function FI_EA_AGROBEE(type) {
      if (type === 3) return FI_EA_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_ENT_ANA;
      if (type === 4) return FI_EA_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_ENT_ANA;
      if (type === 6) return FI_EA_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_ENT_ANA;
      else return 0;
    }

    function FI_SD_BASE(type) {
      if (type === 0) return 120;
      else if (type === UNITS.A_4000) return 96;
      else if (type === 1) return 120;
      else if (type === UNITS.A_2500) return 27;
      else if (type === UNITS.A_BIT) return 26 + 1;
      else if (type === UNITS.A_7000) return 120;
      else if (type === UNITS.A_5500) return 20;
      else if (type === UNITS.A_4500) return 120;
      else return 0;
    }
    //KEEP GOING FROM HERE
    function FI_SA_BASE(type) {
      if (type === UNITS.A_4000) return 96;
      else if (type === UNITS.A_7000) return 120;
      else if (type === UNITS.A_2500) return 27;
      else if (type === UNITS.A_BIT) return 26 + 1;
      else if (type === UNITS.A_4500) return 12;
      else return 0;
    }

    function FI_ED_BASE(type) {
      if (type === 0) return 32;
      else if (type === UNITS.A_4000) return 32;
      else if (type === 1) return 32;
      else if (type === UNITS.A_2500) return 12 + INTERNES_ED_A25; // (2 especials)
      else if (type === UNITS.A_BIT) return 26;
      else if (type === UNITS.A_7000) return 32;
      else if (type === UNITS.A_5500) return 10 + INTERNES_ED_A55; // (2 especials)
      else if (type === UNITS.A_4500) return 32;
      else return 0;
    }

    function FI_ED_VIRTUAL(type) {
      if (type === UNITS.A_2500) return FI_ED_AGROBEE(type) + MAX_VIRTUAL_ENT_DIG;
      else if (type === UNITS.A_BIT) return FI_ED_AGROBEE(type) + MAX_VIRTUAL_ENT_DIG;
      else if (type === UNITS.A_5500) return FI_ED_AGROBEE(type) + MAX_VIRTUAL_ENT_DIG;
      else if (type === UNITS.A_4500) return FI_ED_BASE(type) + 1;
      else return 0;
    }

    function FI_ED_AGROBEEL(type) {
      if (type === UNITS.A_2500) return FI_ED_VIRTUAL(type) + MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG;
      else if (type === UNITS.A_BIT) return FI_ED_VIRTUAL(type) + MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG;
      else if (type === UNITS.A_5500) return FI_ED_VIRTUAL(type) + MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG;
      else if (type === UNITS.A_4500)
        return FI_ED_VIRTUAL(type) + MAXAGROBEEL(type) * (MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG);
      else return 0;
    }

    function FI_EA_VIRTUAL(type) {
      if (type === UNITS.A_2500) return FI_EA_AGROBEE(type) + MAX_VIRTUAL_ENT_ANA;
      if (type === UNITS.A_BIT) return FI_EA_AGROBEE(type) + MAX_VIRTUAL_ENT_ANA;
      if (type === UNITS.A_5500) return FI_EA_AGROBEE(type) + MAX_VIRTUAL_ENT_ANA;
      if (type === UNITS.A_4500) return FI_EA_BASE(type) + 1;
      else return 0;
    }

    function FI_ED_AGROBEE(type) {
      if (type === UNITS.A_2500) return FI_ED_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_ENT_DIG;
      else if (type === UNITS.A_BIT) return FI_ED_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_ENT_DIG;
      else if (type === UNITS.A_5500) return FI_ED_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_ENT_DIG;
      else return 0;
    }
    function FI_EA_AGROBEEL(type) {
      if (type === UNITS.A_2500) return FI_EA_VIRTUAL(type) + MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA;
      if (type === UNITS.A_BIT) return FI_EA_VIRTUAL(type) + MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA;
      if (type === UNITS.A_5500) return FI_EA_VIRTUAL(type) + MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA;
      if (type === UNITS.A_4500) return FI_EA_VIRTUAL(type) + MAXAGROBEEL(type) * (MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA);
      else return 0;
    }
    function FI_EA_BASE_C154(type) {
      if (type === UNITS.A_2500) return FI_EA_AGROBEEL(type) + BASE_C154_EA_A25;
      if (type === UNITS.A_BIT) return FI_EA_AGROBEEL(type) + BASE_C154_EA_A55;
      else return 0;
    }
    function FI_EA_SDI12(type) {
      if (type === UNITS.A_2500) return FI_EA_BASE_C154(type) + MAX_SDI12 * MAX_SDI12_ENT_ANA;
      if (type === UNITS.A_BIT) return FI_EA_BASE_C154(type) + MAX_SDI12 * MAX_SDI12_ENT_ANA;
      if (type === UNITS.A_5500) return FI_EA_BASE_C154(type) + MAX_SDI12 * MAX_SDI12_ENT_ANA;
      else return 0;
    }
    function FI_SD_AGROBEE(type) {
      if (type === UNITS.A_2500) return FI_SD_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_SOR_DIG;
      else if (type === UNITS.A_BIT) return FI_SD_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_SOR_DIG;
      else if (type === UNITS.A_5500) return FI_SD_BASE(type) + MAX_AGROBEE * MAX_AGROBEE_SOR_DIG;
      else return 0;
    }

    function FI_SD_AGROBEEL(type) {
      if (type === UNITS.A_2500) return FI_SD_AGROBEE(type) + MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG;
      else if (type === UNITS.A_BIT) return FI_SD_AGROBEE(type) + MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG;
      else if (type === UNITS.A_5500) return FI_SD_AGROBEE(type) + MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG;
      else if (type === UNITS.A_4500)
        return FI_SD_BASE(type) + MAXAGROBEEL(type) * (MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG);
      else return 0;
    }

    function FI_SD_BASE_C154(type) {
      if (type === UNITS.A_5500) return FI_EA_AGROBEEL(type) + BASE_C154_SD_A55;
      else return 0;
    }

    function FI_EA_DAVIS_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_EA_AGROBEEL(type) + MAX_DAVIS_ENT_ANA;
      } else {
        return 0;
      }
    }

    function FI_SD_MODBUS(type) {
      if (type === UNITS.A_4500) {
        return FI_SD_AGROBEEL(type) + MAX_MODBUS * MAX_MODBUS_SOR_DIG;
      } else {
        return 0;
      }
    }

    function FI_ED_MODBUS(type) {
      if (type === UNITS.A_4500) {
        return FI_ED_AGROBEEL(type) + MAX_MODBUS * MAX_MODBUS_ENT_DIG;
      } else {
        return 0;
      }
    }

    function FI_EA_MODBUS(type) {
      if (type === UNITS.A_4500) {
        return FI_EA_DAVIS_FUNC(type) + MAX_MODBUS * MAX_MODBUS_ENT_ANA;
      } else {
        return 0;
      }
    }

    function FI_SA_MODBUS(type) {
      if (type === UNITS.A_4500) {
        return FI_SA_BASE(type) + MAX_MODBUS * MAX_MODBUS_SOR_ANA;
      } else {
        return 0;
      }
    }

    function FI_SD_AM120_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_SD_MODBUS(type) + MAXEAM(type) * MAX_MAM * MAX_MAM_SOR_DIG;
      } else {
        return FI_SD_AM120;
      }
    }

    function FI_ED_AM120_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_ED_MODBUS(type) + MAXEAM(type) * MAX_MAM * MAX_MAM_ENT_DIG;
      } else {
        return FI_ED_AM120;
      }
    }

    function FI_EA_AM120_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_EA_MODBUS(type) + MAXEAM(type) * MAX_MAM * MAX_MAM_ENT_ANA;
      } else {
        return FI_EA_AM120;
      }
    }

    function FI_SD_AR433_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_SD_AM120_FUNC(type) + MAXEAR433(type) * MAX_MAR433 * MAX_MAR433_SOR_DIG;
      } else {
        return 17994;
      }
    }

    function FI_ED_AR433_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_ED_AM120_FUNC(type) + MAXEAR433(type) * MAX_MAR433 * MAX_MAR433_ENT_DIG;
      } else {
        return 18624;
      }
    }

    function FI_EA_AR433_FUNC(type) {
      if (type === UNITS.A_4500) {
        return FI_EA_AM120_FUNC(type) + MAXEAR433(type) * MAX_MAR433 * MAX_MAR433_ENT_ANA;
      } else {
        return FI_EA_AR433;
      }
    }

    function FI_SD_EXPANSIOBASE(type) {
      if (type === UNITS.A_4500) {
        return FI_SD_AR433_FUNC(type) + MAXEAR433(type) * MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_SOR_DIG;
      } else {
        return 0;
      }
    }

    function FI_ED_EXPANSIOBASE(type) {
      if (type === UNITS.A_4500) {
        return FI_ED_AR433_FUNC(type) + MAXEAR433(type) * MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_ENT_DIG;
      } else {
        return 0;
      }
    }

    function FI_EA_EXPANSIOBASE(type) {
      if (type === UNITS.A_4500) {
        return FI_EA_AR433_FUNC(type) + MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_ENT_ANA;
      } else {
        return 0;
      }
    }

    function FI_SA_EXPANSIOBASE(type) {
      if (type === UNITS.A_4500) {
        return FI_SA_MODBUS(type) + MAXEAR433(type) * MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_SOR_ANA;
      } else {
        return 0;
      }
    }

    function decodeInput8(output, type, io) {
      //IntToCodificacio
      var _tipus, _caixa, _modul, _numes, result;
      var lval = output;

      if (type === UNITS.A_4500) {
        if (lval <= io.digitalin.BASE) {
          // base
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= io.digitalin.VIRTUAL) {
          // virtual
          _tipus = io.types.Virtual;
          _caixa = 0;
          _modul = 0;
          _numes = 1;
        } else if (lval <= io.digitalin.AGROBEEL) {
          //Agrobee-L
          _tipus = io.types.AgroBeeL;
          lval -= io.digitalin.VIRTUAL + 1; //Virtual+1
          _caixa = lval / (MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG) + 1;
          lval %= MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG;
          _modul = lval / MAX_AGROBEEL_ENT_DIG + 1;
          _numes = (lval % MAX_AGROBEEL_ENT_DIG) + 1;
        } else if (lval <= io.digitalin.MB) {
          //ModBus
          _tipus = io.types.ModBus;
          lval -= io.digitalin.AGROBEEL + 1;
          _caixa = 0;
          lval %= MAX_MODBUS * MAX_MODBUS_ENT_DIG;
          _modul = lval / MAX_MODBUS_ENT_DIG + 1;
          _numes = (lval % MAX_MODBUS_ENT_DIG) + 1;
        } else if (lval <= io.digitalin.AM120) {
          //A Monocable
          _tipus = io.types.AMonocable120;
          lval -= io.digitalin.MB + 1;
          _caixa = lval / (MAX_MAM * MAX_MAM_ENT_DIG) + 1;
          lval %= MAX_MAM * MAX_MAM_ENT_DIG;
          _modul = lval / MAX_MAM_ENT_DIG + 1;
          _numes = (lval % MAX_MAM_ENT_DIG) + 1;
        } else if (lval <= io.digitalin.AR433) {
          //A Radio 433
          _tipus = io.types.ARadio433;
          lval -= io.digitalin.AM120 + 1;
          _caixa = lval / (MAX_MAR433 * MAX_MAR433_ENT_DIG) + 1;
          lval %= MAX_MAR433 * MAX_MAR433_ENT_DIG;
          _modul = lval / MAX_MAR433_ENT_DIG + 1;
          _numes = (lval % MAX_MAR433_ENT_DIG) + 1;
        } else if (lval <= io.digitalin.BE) {
          //Expansio Base
          _tipus = io.types.BaseExpansion;
          lval -= io.digitalin.AR433 + 1;
          _caixa = 0;
          lval %= MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_ENT_DIG;
          _modul = lval / MAX_EXPANSIOBASE_ENT_DIG + 1;
          _numes = (lval % MAX_EXPANSIOBASE_ENT_DIG) + 1;
        }
      } else {
        if (lval <= io.digitalin.BASE) {
          // base

          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= io.digitalin.ME) {
          // mòdul d'expansió

          _tipus = io.types.Expansio;
          lval -= io.digitalin.BASE + 1;
          _caixa = 0;
          _modul = lval / 2 + 1;
          _numes = (lval % 2) + 1;
        } else if (lval <= io.digitalin.AR868) {
          // Agrònic Ràdio 868

          _tipus = io.types.Radio868;
          lval -= io.digitalin.ME + 1;
          _caixa = lval / 256 + 1;
          lval %= 256;
          _modul = lval / 8 + 1;
          _numes = (lval % 8) + 1;
        } else if (lval <= io.digitalin.AM64) {
          // Agrònic Monocable 64

          _tipus = io.types.Monocable64;
          lval -= io.digitalin.AR868 + 1;
          _caixa = lval / 128 + 1;
          lval %= 128;
          _modul = lval / 2 + 1;
          _numes = (lval % 2) + 1;
        } else if (lval <= io.digitalin.AM120) {
          // Agrònic Monocable 120

          _tipus = io.types.Monocable120;
          lval -= io.digitalin.AM64 + 1;
          _caixa = lval / 1200 + 1;
          lval %= 1200;
          _modul = lval / 10 + 1;
          _numes = (lval % 10) + 1;
        } else if (lval <= io.digitalin.AR16) {
          // Agrònic Ràdio 868-16
          _tipus = io.types.Radio868_16;
          lval -= io.digitalin.AM120 + 1;
          _caixa = lval / 1024 + 1;
          lval %= 1024;
          _modul = lval / 16 + 1;
          _numes = (lval % 16) + 1;
        } else if (lval <= io.digitalin.AR24) {
          // Agrònic Ràdio 2.4
          _tipus = io.types.Radio24;
          lval -= io.digitalin.AR16 + 1;
          _caixa = lval / 1920 + 1;
          lval %= 1920;
          _modul = lval / 16 + 1;
          _numes = (lval % 16) + 1;
        } else if (lval <= io.digitalin.AR433) {
          // Agrònic Ràdio 433
          _tipus = io.types.Radio433;
          lval -= io.digitalin.AR24 + 1;
          _caixa = lval / 960 + 1;
          lval %= 960;
          _modul = lval / 16 + 1;
          _numes = (lval % 16) + 1;
        }
      }

      if (_tipus < 0 || _caixa < 0 || _modul < 0 || _numes < 0) return '00000000';

      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };

      return result;
    }

    function decodeOutput8(input, type, io) {
      var _tipus, _caixa, _modul, _numes, result;
      var lval = input;

      if (type === UNITS.A_4500) {
        if (lval <= io.digitalout.BASE) {
          // base
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= io.digitalout.AGROBEEL) {
          //Agrobee-L
          _tipus = io.types.AgroBeeL;
          lval -= io.digitalout.BASE + 1; //Base+1
          _caixa = lval / (MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG) + 1;
          lval %= MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG;
          _modul = lval / MAX_AGROBEEL_SOR_DIG + 1;
          _numes = (lval % MAX_AGROBEEL_SOR_DIG) + 1;
        } else if (lval <= io.digitalout.MB) {
          //ModBus
          _tipus = io.types.ModBus;
          lval -= io.digitalout.AGROBEEL + 1;
          _caixa = 0;
          lval %= MAX_MODBUS * MAX_MODBUS_SOR_DIG;
          _modul = lval / MAX_MODBUS_SOR_DIG + 1;
          _numes = (lval % MAX_MODBUS_SOR_DIG) + 1;
        } else if (lval <= io.digitalout.AM120) {
          //A Monocable
          _tipus = io.types.AMonocable120;
          lval -= io.digitalout.MB + 1;
          _caixa = lval / (MAX_MAM * MAX_MAM_SOR_DIG) + 1;
          lval %= MAX_MAM * MAX_MAM_SOR_DIG;
          _modul = lval / MAX_MAM_SOR_DIG + 1;
          _numes = (lval % MAX_MAM_SOR_DIG) + 1;
        } else if (lval <= io.digitalout.AR433) {
          //A Radio 433
          _tipus = io.types.ARadio433;
          lval -= io.digitalout.AM120 + 1;
          _caixa = lval / (MAX_MAR433 * MAX_MAR433_SOR_DIG) + 1;
          lval %= MAX_MAR433 * MAX_MAR433_SOR_DIG;
          _modul = lval / MAX_MAR433_SOR_DIG + 1;
          _numes = (lval % MAX_MAR433_SOR_DIG) + 1;
        } else if (lval <= io.digitalout.BE) {
          //Expansio Base
          _tipus = io.types.BaseExpansion;
          lval -= io.digitalout.AR433 + 1;
          _caixa = 0;
          lval %= MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_SOR_DIG;
          _modul = lval / MAX_EXPANSIOBASE_SOR_DIG + 1;
          _numes = (lval % MAX_EXPANSIOBASE_SOR_DIG) + 1;
        }
      } else {
        if (lval <= io.digitalout.BASE) {
          //Base
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= io.digitalout.ME) {
          // Mòdul d'expansió

          _tipus = io.types.Expansio;
          _caixa = 0;
          lval -= io.digitalout.BASE + 1;
          _modul = lval / 5 + 1;
          _numes = (lval % 5) + 1;
        } else if (lval <= io.digitalout.AR868) {
          // Agrònic Ràdio 868

          _tipus = io.types.Radio868;
          lval -= io.digitalout.ME + 1;
          _caixa = lval / 256 + 1;
          lval %= 256;
          _modul = lval / 8 + 1;
          _numes = (lval % 8) + 1;
        } else if (lval <= io.digitalout.AM64) {
          // Agrònic Monocable 64

          _tipus = io.types.Monocable64;
          lval -= io.digitalout.AR868 + 1;
          _caixa = lval / 64 + 1;
          lval %= 64;
          _modul = lval + 1;
          _numes = 1;
        } else if (lval <= io.digitalout.RIT) {
          // radio Itowa

          _tipus = io.types.RadioItowa;
          _caixa = 0;
          lval -= io.digitalout.AM64 + 1;
          _modul = lval / 15 + 1;
          _numes = (lval % 15) + 1;
        } else if (lval <= io.digitalout.AM120) {
          // Agrònic Monocable 120

          _tipus = io.types.Monocable120;
          lval -= io.digitalout.RIT + 1;
          _caixa = lval / 960 + 1;
          lval %= 960;
          _modul = lval / 8 + 1;
          _numes = (lval % 8) + 1;
        } else if (lval <= io.digitalout.AR16) {
          // Agrònic Ràdio 868-16

          _tipus = io.types.Radio868_16;
          lval -= io.digitalout.AM120 + 1;
          _caixa = lval / 1024 + 1;
          lval %= 1024;
          _modul = lval / 16 + 1;
          _numes = (lval % 16) + 1;
        } else if (lval <= io.digitalout.AR24) {
          // Agrònic Ràdio 2.4

          _tipus = io.types.Radio24;
          lval -= io.digitalout.AR16 + 1;
          _caixa = lval / 1920 + 1;
          lval %= 1920;
          _modul = lval / 16 + 1;
          _numes = (lval % 16) + 1;
        } else if (lval <= io.digitalout.AR433) {
          // Agrònic Ràdio 433

          _tipus = io.types.Radio433;
          lval -= io.digitalout.AR24 + 1;
          _caixa = lval / 960 + 1;
          lval %= 960;
          _modul = lval / 16 + 1;
          _numes = (lval % 16) + 1;
        }
      }

      if (_tipus < 0 || _caixa < 0 || _modul < 0 || _numes < 0) return '00000000';

      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };

      return result;
    }

    function decodeInputA8(input, type, io) {
      //Analog input
      var _tipus, _caixa, _modul, _numes, result;
      var lval = input;
      if (type === UNITS.A_4500) {
        if (lval <= FI_EA_BASE(type) - INTERNES_EA_A45) {
          // base
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= FI_EA_BASE(type)) {
          // base sensors especifics
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 1;
          _numes = lval - (FI_EA_BASE(type) - INTERNES_EA_A45);
        } else if (lval <= io.analogin.Virtual) {
          // virtual
          _tipus = io.types.Virtual;
          _caixa = 0;
          _modul = 0;
          _numes = 1;
        } else if (lval <= io.analogin.AGROBEEL) {
          //Agrobee-L
          _tipus = io.types.AgroBeeL;
          //lval -= (io.analogin.Virtual) +1 //Virtual+1
          lval -= FI_EA_BASE(type) + 1 + 1;
          _caixa = lval / (MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA) + 1;
          lval %= MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA;
          _modul = lval / MAX_AGROBEEL_ENT_ANA + 1;
          _numes = (lval % MAX_AGROBEEL_ENT_ANA) + 1;
        } else if (lval <= io.analogin.Davis) {
          _tipus = io.types.Davis;
          lval -= io.analogin.AGROBEEL + 1; //Davis
          _caixa = 0;
          _modul = 0;
          _numes = 1;
        } else if (lval <= io.analogin.MB) {
          //ModBus
          _tipus = io.types.ModBus;
          lval -= io.analogin.Davis + 1;
          _caixa = 0;
          lval %= MAX_MODBUS * MAX_MODBUS_ENT_ANA;
          _modul = lval / MAX_MODBUS_ENT_ANA + 1;
          _numes = (lval % MAX_MODBUS_ENT_ANA) + 1;
        } else if (lval <= io.analogin.AM120) {
          //A Monocable
          _tipus = io.types.AMonocable120;
          lval -= io.analogin.MB + 1;
          _caixa = lval / (MAX_MAM * MAX_MAM_ENT_ANA) + 1;
          lval %= MAX_MAM * MAX_MAM_ENT_ANA;
          _modul = lval / MAX_MAM_ENT_ANA + 1;
          _numes = (lval % MAX_MAM_ENT_ANA) + 1;
        } else if (lval <= io.analogin.AR433) {
          //A Radio 433
          _tipus = io.types.ARadio433;
          lval -= io.analogin.AM120 + 1;
          _caixa = lval / (MAX_MAR433 * MAX_MAR433_ENT_ANA) + 1;
          lval %= MAX_MAR433 * MAX_MAR433_ENT_ANA;
          _modul = lval / MAX_MAR433_ENT_ANA + 1;
          _numes = (lval % MAX_MAR433_ENT_ANA) + 1;
        } else if (lval <= io.analogin.BE) {
          //Expansio Base
          _tipus = io.types.BaseExpansion;
          lval -= io.analogin.AR433 + 1;
          _caixa = 0;
          lval %= MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_ENT_ANA;
          _modul = lval / MAX_EXPANSIOBASE_ENT_ANA + 1;
          _numes = (lval % MAX_EXPANSIOBASE_ENT_ANA) + 1;
        } else if (lval <= io.analogin.SDI12) {
          //SDI12
          _tipus = io.types.SDI12;
          lval -= io.analogin.BE + 1;
          _caixa = 0;
          lval %= MAX_SDI12 * MAX_SDI12_ENT_ANA;
          _modul = lval / MAX_SDI12_ENT_ANA + 1;
          _numes = (lval % MAX_SDI12_ENT_ANA) + 1;
        }
      } else {
        if (lval <= FI_EA_BASE(type)) {
          // base

          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= FI_EA_ME) {
          // mòdul d'expansió

          _tipus = io.types.Expansio;
          _caixa = 0;
          lval -= FI_EA_BASE(type) + 1;
          _modul = lval / 4 + 1;
          _numes = (lval % 4) + 1;
        } else if (lval <= FI_EA_AR868) {
          // Agrònic Ràdio 868

          _tipus = io.types.Radio868;
          lval -= FI_EA_ME + 1;
          _caixa = lval / 64 + 1;
          lval %= 64;
          _modul = lval / 2 + 1;
          _numes = (lval % 2) + 1;
        } else if (lval <= FI_EA_SMS) {
          // missatges SMS

          _tipus = io.types.SMS;
          lval -= FI_EA_AR868 + 1;
          _caixa = 0;
          _modul = 0;
          _numes = lval + 1;
        } else if (lval <= FI_EA_MI) {
          // MicroIsis

          _tipus = io.types.MicroIsis;
          lval -= FI_EA_SMS + 1;
          _caixa = 0;
          _modul = lval / 8 + 1;
          _numes = (lval % 8) + 1;
        } else if (lval <= FI_EA_MET) {
          // uMetos
        } else if (lval <= FI_EA_AM120) {
          // Agrònic Monocable 120

          _tipus = io.types.Monocable120;
          lval -= FI_EA_MET + 1;
          _caixa = lval / 120 + 1;
          lval %= 120;
          _modul = lval + 1;
          _numes = 1;
        } else if (lval <= FI_EA_AR16) {
          // Agrònic Ràdio 868-16

          _tipus = io.types.Radio868_16;
          lval -= FI_EA_AM120 + 1;
          _caixa = lval / 128 + 1;
          lval %= 128;
          _modul = lval / 2 + 1;
          _numes = (lval % 2) + 1;
        } else if (lval <= FI_EA_AR24) {
          // Agrònic Ràdio 2.4

          _tipus = io.types.Radio24;
          lval -= FI_EA_AR16 + 1;
          _caixa = lval / 240 + 1;
          lval %= 240;
          _modul = lval / 2 + 1;
          _numes = (lval % 2) + 1;
        } else if (lval <= FI_EA_AR433) {
          // Agrònic Ràdio 433

          _tipus = io.types.Radio433;
          lval -= FI_EA_AR24 + 1;
          _caixa = lval / 120 + 1;
          lval %= 120;
          _modul = lval / 2 + 1;
          _numes = (lval % 2) + 1;
        } else if (lval <= FI_EA_AM120_2) {
          // Agrònic Monocable 120, EA 2

          _tipus = io.types.Monocable120;
          lval -= FI_EA_AR433 + 1;
          _caixa = lval / 120 + 1;
          lval %= 120;
          _modul = lval + 1;
          _numes = 2;
        } else if (lval <= FI_EA_DAVIS) {
          // Estació Davis

          _tipus = io.types.Davis;
          lval -= FI_EA_AM120_2;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        }
      }
      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };

      return result;
    }

    function decodeOutputA8(output, type, io) {
      var _tipus, _caixa, _modul, _numes, result;
      var lval = output;
      if (type == UNITS.A_4500) {
        if (lval <= io.analogout.BASE) {
          // base
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        } else if (lval <= io.analogout.MB) {
          //ModBus
          _tipus = io.types.ModBus;
          lval -= io.analogout.BASE + 1;
          _caixa = 0;
          lval %= MAX_MODBUS * MAX_MODBUS_SOR_ANA;
          _modul = lval / MAX_MODBUS_SOR_ANA + 1;
          _numes = (lval % MAX_MODBUS_SOR_ANA) + 1;
        } else if (lval <= io.analogout.BE) {
          //Expansio Base
          _tipus = io.types.BaseExpansion;
          lval -= io.analogout.MB + 1;
          _caixa = 0;
          lval %= MAX_EXPANSIOBASE * MAX_EXPANSIOBASE_SOR_ANA;
          _modul = lval / MAX_EXPANSIOBASE_SOR_ANA + 1;
          _numes = (lval % MAX_EXPANSIOBASE_SOR_ANA) + 1;
        }
      } else {
        if (lval <= io.analogout.BASE) {
          // base
          _tipus = io.types.Base;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        }
      }

      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };

      return result;
    }

    function decodeDigitalInput5(input, type) {
      var _tipus, _caixa, _modul, _numes, result;
      var lval = input;

      if (lval <= FI_ED_BASE(type)) {
        //Base
        if (type === 3) {
          if (lval <= FI_ED_BASE(type) - INTERNES_ED_A25) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          } else if (lval <= FI_ED_BASE(type)) {
            // 2 internes
            _tipus = 0;
            _caixa = 0;
            _modul = 1;
            _numes = lval - (FI_ED_BASE(type) - INTERNES_ED_A25);
          }
        }
        if (type === 4) {
          if (lval <= FI_ED_BASE(type)) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          }
        }

        if (type === 6) {
          if (lval <= FI_ED_BASE(type) - INTERNES_ED_A55) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          } else if (lval <= FI_ED_BASE(type)) {
            // 2 internes
            _tipus = 0;
            _caixa = 0;
            _modul = 1;
            _numes = lval - (FI_ED_BASE(type) - INTERNES_ED_A55);
          }
        }
      } else if (lval <= FI_ED_AGROBEE(type)) {
        // AgroBee
        _tipus = 1;
        _caixa = 0;
        lval -= FI_ED_BASE(type) + 1;
        _modul = lval / MAX_AGROBEE_ENT_DIG + 1;
        _numes = (lval % MAX_AGROBEE_ENT_DIG) + 1;
      } else if (lval <= FI_ED_VIRTUAL(type)) {
        // Virtual
        _tipus = 2;
        _caixa = 0;
        _modul = 0;
        lval -= FI_ED_AGROBEE(type);
        _numes = lval; // es lval pq no hi ha moduls virtuals
      } else if (lval <= FI_ED_AGROBEEL(type)) {
        // AgroBeeL
        _tipus = 3;
        _caixa = 0;
        lval -= FI_ED_VIRTUAL(type) + 1;
        _modul = lval / MAX_AGROBEEL_ENT_DIG + 1;
        _numes = (lval % MAX_AGROBEEL_ENT_DIG) + 1;
      }
      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };
      return result;
    }

    function decodeAnalogInput5(input, type) {
      var _tipus, _caixa, _modul, _numes, result;
      var lval = input;

      if (lval <= FI_EA_BASE(type)) {
        // Base
        if (type === 3) {
          if (lval <= FI_EA_BASE(type) - INTERNES_EA_A25) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          } else if (lval <= FI_EA_BASE(type)) {
            // 3 internes
            _tipus = 0;
            _caixa = 0;
            _modul = 1;
            _numes = lval - (FI_EA_BASE(type) - INTERNES_EA_A25);
          }
        }
        if (type === 4) {
          if (lval <= FI_EA_BASE(type) - INTERNES_EA_AB) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          } else if (lval <= FI_EA_BASE(type)) {
            // 3 internes
            _tipus = 0;
            _caixa = 0;
            _modul = 1;
            _numes = lval - (FI_EA_BASE(type) - INTERNES_EA_AB);
          }
        }
        if (type === 6) {
          if (lval <= FI_EA_BASE(type) - INTERNES_EA_A55) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          } else if (lval <= FI_EA_BASE(type)) {
            // 3 internes
            _tipus = 0;
            _caixa = 0;
            _modul = 1;
            _numes = lval - (FI_EA_BASE(type) - INTERNES_EA_A55);
          }
        }
      } else if (lval <= FI_EA_AGROBEE(type)) {
        // AgroBee
        _tipus = 1;
        _caixa = 0;
        lval -= FI_EA_BASE(type) + 1;
        _modul = lval / MAX_AGROBEE_ENT_ANA + 1;
        _numes = (lval % MAX_AGROBEE_ENT_ANA) + 1;
      } else if (lval <= FI_EA_VIRTUAL(type)) {
        // Virtual
        _tipus = 2;
        _caixa = 0;
        _modul = 0;
        //lval -= FI_EA_AGROBEE(type);
        _numes = 1; // es lval pq no hi ha moduls virtuals
      } else if (lval <= FI_EA_AGROBEEL(type)) {
        _tipus = 3;
        _caixa = 0;
        lval -= FI_EA_VIRTUAL(type) + 1;
        _modul = lval / MAX_AGROBEEL_ENT_ANA + 1;
        _numes = (lval % MAX_AGROBEEL_ENT_ANA) + 1;
      } else if (lval <= FI_EA_BASE_C154(type)) {
        _tipus = 0;
        _caixa = 0;
        lval -= FI_EA_AGROBEEL(type) + 1;
        _modul = 2;
        _numes = (lval % 100) + 1;
      } else if (lval <= FI_EA_SDI12(type)) {
        // SDI12
        _tipus = 4;
        _caixa = 0;
        lval -= FI_EA_BASE_C154(type) + 1;
        _modul = lval / MAX_SDI12_ENT_ANA + 1;
        _numes = (lval % MAX_SDI12_ENT_ANA) + 1;
      } else if (lval <= FI_EA_DAVIS) {
        // Estació Davis
        _tipus = 5;
        lval -= FI_EA_SDI12(type) + 1;
        _caixa = 0;
        _modul = 0;
        _numes = lval + 1;
      }
      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };
      return result;
    }

    function decodeDigitalOutput5(input, type) {
      var _tipus, _caixa, _modul, _numes, result;
      var lval = input;
      if (lval <= FI_SD_BASE(type)) {
        // base
        if (type === 3) {
          _tipus = 0;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        }
        if (type === 4) {
          if (lval <= FI_SD_BASE(type) - INTERNES_SD_AB) {
            _tipus = 0;
            _caixa = 0;
            _modul = 0;
            _numes = lval;
          } else if (lval <= FI_SD_BASE(type)) {
            // 1 interna
            _tipus = 0;
            _caixa = 0;
            _modul = 1;
            _numes = lval - (FI_SD_BASE(type) - INTERNES_SD_AB);
          }
        }

        if (type === 6) {
          _tipus = 0;
          _caixa = 0;
          _modul = 0;
          _numes = lval;
        }
      } else if (lval <= FI_SD_AGROBEE(type)) {
        // AgrooBee
        _tipus = 1;
        lval -= FI_SD_BASE(type) + 1;
        _caixa = 0;
        _modul = lval / MAX_AGROBEE_SOR_DIG + 1;
        _numes = (lval % MAX_AGROBEE_SOR_DIG) + 1;
      } else if (lval <= FI_SD_AGROBEEL(type)) {
        // AgrooBeeL
        _tipus = 3;
        lval -= FI_SD_AGROBEE(type) + 1;
        _caixa = 0;
        _modul = lval / MAX_AGROBEEL_SOR_DIG + 1;
        _numes = (lval % MAX_AGROBEEL_SOR_DIG) + 1;
      } else if (lval <= FI_SD_BASE_C154(type)) {
        // C154
        if (type == 6) {
          _tipus = 0;
          lval -= FI_SD_AGROBEEL(type) + 1;
          lval += FI_SD_BASE(type);
          _caixa = 0;
          _modul = 0;
          _numes = lval + 1;
        }
      }
      result = {
        type: _tipus | 0,
        box: _caixa | 0,
        module: _modul | 0,
        number: _numes | 0,
      };
      return result;
    }

    function encodeDigitalInput5(input, type) {
      var _tipus = Number(input.substring(0, 1)); // camp1 = x
      var _caixa = 0;
      var _modul = Number(input.substring(1, 3)); // camp3 = xx
      var _numes = Number(input.substring(3, 5)); // camp4 = xx
      var lval;
      switch (_tipus) {
        case 0: //Base
          if (type === 3) {
            //Agronic2500
            if (_modul == 0) {
              if (_numes >= 1 && _numes <= 12)
                //2 internes
                lval = _numes;
            }
            if (_modul == 1) {
              // Ent. internes,, així retorna ent correcta(no vermell)
              if (_numes >= 1 && _numes <= 2) lval = 2 + _numes;
            }
          }
          if (type == 4) {
            //AgronicBit
            if (_numes >= 1 && _numes <= 25) lval = _numes;
          }

          if (type == 6) {
            //Agronic5500
            if (_modul == 0) {
              if (_numes >= 1 && _numes <= 10)
                // 2 internes
                lval = _numes;
            }
            if (_modul == 1) {
              // Ent. internes,, així retorna ent correcta(no vermell)
              if (_numes >= 1 && _numes <= 1) lval = 10 + _numes;
            }
          }

          break;

        case 1: //Agrobee
          if (_modul >= 1 && _modul <= MAX_AGROBEE) {
            if (_numes >= 1 && _numes <= MAX_AGROBEE_ENT_DIG) {
              lval = (_modul - 1) * MAX_AGROBEE_ENT_DIG + _numes + 14;
            }
          }
          break;

        case 2: //Virtual
          /*                if (_modul == 0) {
                    
                    if (_numes >= 1 && _numes <= MAX_VIRTUAL_ENT_DIG) {
                        lval = _numes + (14 + (MAX_AGROBEE * MAX_AGROBEE_ENT_DIG));
                    }
                }*/
          lval = 159;
          break;

        case 3: //AgroBeeL
          if (_modul >= 1 && _modul <= MAX_AGROBEEL)
            if (_numes >= 1 && _numes <= MAX_AGROBEEL_ENT_DIG)
              lval = (_modul - 1) * MAX_AGROBEEL_ENT_DIG + _numes + FI_ED_VIRTUAL(type);
          break;
      }
      return lval;
    }

    function encodeAnalogInput5(input, type) {
      var _tipus = Number(input.substring(0, 1)); // camp1 = x
      var _caixa = 0;
      var _modul = Number(input.substring(1, 3)); // camp3 = xx
      var _numes = Number(input.substring(3, 5)); // camp4 = xx
      var lval;
      switch (_tipus) {
        case 0: //BASE
          if (type === 3) {
            if (_modul == 0) {
              if (_numes >= 1 && _numes <= FI_EA_BASE(type) - INTERNES_EA_A25) lval = _numes;
            }
            if (_modul == 1) {
              // Ent. Internes
              if (_numes >= 1 && _numes <= INTERNES_EA_A25) lval = FI_EA_BASE(type) - INTERNES_EA_A25 + _numes;
            }
            if (_modul == 2) {
              // Ent. Internes C154
              if (_numes >= 1 && _numes <= BASE_C154_EA_A25) lval = FI_EA_AGROBEEL(type) + _numes;
            }
          }
          if (type === 4) {
            if (_modul == 0) {
              if (_numes >= 1 && _numes <= FI_EA_BASE(type) - INTERNES_EA_AB) lval = _numes;
            }
            if (_modul == 1) {
              // Ent. Internes
              if (_numes >= 1 && _numes <= INTERNES_EA_AB) lval = FI_EA_BASE(type) - INTERNES_EA_AB + _numes;
            }
          }

          if (type === 6) {
            if (_modul == 0) {
              if (_numes >= 1 && _numes <= FI_EA_BASE(type) - INTERNES_EA_A55) lval = _numes;
            }
            if (_modul == 1) {
              // Ent. Internes
              if (_numes >= 1 && _numes <= INTERNES_EA_A55) lval = FI_EA_BASE(type) - INTERNES_EA_A55 + _numes;
            }
            if (_modul == 2) {
              // Ent. Internes C154
              if (_numes >= 1 && _numes <= BASE_C154_EA_A55) lval = FI_EA_AGROBEEL(type) + _numes;
            }
          }

          break;
        case 1: //AGROBEE
          if (_modul >= 1 && _modul <= MAX_AGROBEE)
            if (_numes >= 1 && _numes <= MAX_AGROBEE_ENT_ANA)
              lval = (_modul - 1) * MAX_AGROBEE_ENT_ANA + _numes + FI_EA_BASE(type);
          break;

        case 2: //VIRTUAL
          if (_modul == 0) if (_numes >= 1 && _numes <= MAX_VIRTUAL_ENT_ANA) lval = _numes + FI_EA_AGROBEE(type);
          break;

        case 3: //AGROBEEL
          if (_modul >= 1 && _modul <= MAX_AGROBEEL)
            if (_numes >= 1 && _numes <= MAX_AGROBEEL_ENT_ANA)
              lval = (_modul - 1) * MAX_AGROBEEL_ENT_ANA + _numes + FI_EA_VIRTUAL(type);
          break;
        case 4: //SDI12
          if (_modul >= 1 && _modul <= MAX_SDI12) {
            if (_numes >= 1 && _numes <= MAX_SDI12_ENT_ANA) {
              lval = (_modul - 1) * MAX_SDI12_ENT_ANA + _numes + FI_EA_BASE_C154(type);
            }
          }
          break;
        case 5: //DAVIS
          if (_caixa == 0)
            if (_modul == 0) if (_numes >= 1 && _numes <= MAX_DAVIS_ENT_ANA) lval = _numes + FI_EA_SDI12(type);
          break;
      }
      return lval;
    }

    function encodeDigitalOutput5(input, type) {
      var _tipus = Number(input.substring(0, 1)); // camp1 = x
      var _caixa = 0;
      var _modul = Number(input.substring(1, 3)); // camp3 = xx
      var _numes = Number(input.substring(3, 5)); // camp4 = xx
      var lval;
      switch (_tipus) {
        case 0:
          if (type === 3) {
            if (_modul === 0) if (_numes >= 1 && _numes <= FI_SD_BASE(type)) lval = _numes;
          }
          if (type === 4) {
            if (_modul === 0) {
              if (_numes >= 1 && _numes <= FI_SD_BASE(type) - INTERNES_SD_AB) lval = _numes;
            }
            if (_modul === 1) {
              // Ent. Internes
              if (_numes >= 1 && _numes <= INTERNES_SD_AB) lval = FI_SD_BASE(type) - INTERNES_SD_AB + _numes;
            }
          }

          if (type === 6) {
            if (_modul === 0) if (_numes >= 1 && _numes <= FI_SD_BASE(type)) lval = _numes;
          }

          break;
        case 1:
          if (_modul >= 1 && _modul <= MAX_AGROBEE)
            if (_numes >= 1 && _numes <= MAX_AGROBEE_SOR_DIG)
              lval = (_modul - 1) * MAX_AGROBEE_SOR_DIG + _numes + FI_SD_BASE(type);
          break;
        case 3:
          if (_modul >= 1 && _modul <= MAX_AGROBEEL)
            if (_numes >= 1 && _numes <= MAX_AGROBEEL_SOR_DIG)
              lval = (_modul - 1) * MAX_AGROBEEL_SOR_DIG + _numes + FI_SD_AGROBEE(type);
          break;
      }
      return lval;
    }

    function encodeAnalogOutput5(input, type) {
      var _tipus = input.substring(0, 1); // camp1 = x
      var _caixa = 0;
      var _modul = input.substring(1, 3); // camp3 = xx
      var _numes = input.substring(3, 5); // camp4 = xx
      var lval;
      if (_modul === 0) {
        if (_numes >= 1 && _numes <= FI_SA_BASE(type)) lval = _numes;
      }
      return lval;
    }

    function encodeInputA8(input, type, io) {
      //Analog Input
      if (input.length !== 8) {
        return -1;
      }

      var _tipus, _caixa, _modul, _numes, lval;

      _tipus = Number(input.substring(0, 2));
      _caixa = Number(input.substring(2, 3));
      _modul = Number(input.substring(3, 6));
      _numes = Number(input.substring(6, 8));
      if (type === UNITS.A_4500) {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0)
              if (_modul == 0) {
                if (_numes >= 1 && _numes <= FI_EA_BASE(type) - 2) lval = _numes;
              } else if (_modul == 1) {
                // Aix� es que sortides > 2 digits
                if (MAX_BASE_ENT_ANA + _numes <= FI_EA_BASE(type)) lval = MAX_BASE_ENT_ANA + _numes;
              }
            break;
          case io.types.Virtual:
            if (_caixa == 0 && _modul == 0 && _numes == 1) lval = 1 + FI_EA_BASE(type);
            break;
          case io.types.AgroBeeL:
            if (
              0 < _caixa &&
              _caixa <= MAXAGROBEEL(type) &&
              0 < _modul &&
              _modul <= MAX_AGROBEEL &&
              0 < _numes &&
              _numes <= MAX_AGROBEEL_ENT_ANA
            )
              lval =
                (_caixa - 1) * MAX_AGROBEEL * MAX_AGROBEEL_ENT_ANA +
                (_modul - 1) * MAX_AGROBEEL_ENT_ANA +
                _numes +
                FI_EA_VIRTUAL(type);
            break;
          case io.types.Davis:
            if (_caixa == 0 && _modul == 0 && _numes >= 1 && _numes <= MAX_DAVIS_ENT_ANA)
              lval = _numes + FI_EA_AGROBEEL(type);
            break;
          case io.types.ModBus:
            if (_caixa == 0)
              if (_caixa == 0 && 0 < _modul && _modul <= MAX_MODBUS && 0 < _numes && _numes <= MAX_MODBUS_ENT_ANA)
                lval = (_modul - 1) * MAX_MODBUS_ENT_ANA + _numes + FI_EA_DAVIS_FUNC(type);
            break;
          case io.types.AMonocable120:
            if (
              0 < _caixa &&
              _caixa <= MAXEAM(type) &&
              0 < _modul &&
              _modul <= MAX_MAM &&
              0 < _numes &&
              _numes <= MAX_MAM_ENT_ANA
            )
              lval =
                (_caixa - 1) * MAX_MAM * MAX_MAM_ENT_ANA + (_modul - 1) * MAX_MAM_ENT_ANA + _numes + FI_EA_MODBUS(type);
            break;
          case io.types.ARadio433:
            if (
              0 < _caixa &&
              _caixa <= MAXEAR433(type) &&
              0 < _modul &&
              _modul <= MAX_MAR433 &&
              0 < _numes &&
              _numes <= MAX_MAR433_ENT_ANA
            )
              lval =
                (_caixa - 1) * MAX_MAR433 * MAX_MAR433_ENT_ANA +
                (_modul - 1) * MAX_MAR433_ENT_ANA +
                _numes +
                FI_EA_AM120_FUNC(type);
            break;
          case io.types.BaseExpansion:
            if (
              _caixa == 0 &&
              0 < _modul &&
              _modul <= MAX_EXPANSIOBASE &&
              0 < _numes &&
              _numes <= MAX_EXPANSIOBASE_ENT_ANA
            )
              lval = (_modul - 1) * MAX_EXPANSIOBASE_ENT_ANA + _numes + FI_EA_AR433_FUNC(type);
            break;
          case io.types.SDI12:
            if (_caixa == 0 && 0 < _modul && _modul <= MAX_SDI12 && 0 < _numes && _numes <= MAX_SDI12_ENT_ANA)
              lval = (_modul - 1) * MAX_SDI12_ENT_ANA + _numes + FI_EA_EXPANSIOBASE(type);
            break;
        }
      } else {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0) if (_modul == 0) if (_numes >= 1 && _numes <= FI_SD_BASE(type)) lval = _numes;
            break;
          case io.types.Expansio:
            if (_caixa == 0)
              if (_modul >= 1 && _modul <= io.max.ME)
                if (_numes >= 1 && _numes <= io.analogin.max.ME)
                  lval = (_modul - 1) * io.analogin.max.ME + _numes + FI_EA_BASE(type);
            break;
          case io.types.Radio868:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MR)
                if (_numes >= 1 && _numes <= io.analogin.max.MR)
                  //MAXCER(equip)
                  lval =
                    (_caixa - 1) * io.max.MR * io.analogin.max.MR +
                    (_modul - 1) * io.analogin.max.MR +
                    _numes +
                    FI_EA_ME;
            break;
          case io.types.SMS:
            if (_caixa == 0)
              if (_modul == 0) if (_numes >= 1 && _numes <= io.analogin.max.SMS) lval = _numes + FI_EA_AR868;
            break;
          case io.types.MicroIsis:
            if (_caixa == 0)
              if (_modul >= 1 && _modul <= io.max.MISIS)
                if (_numes >= 1 && _numes <= io.analogin.max.MISIS)
                  lval = (_modul - 1) * io.analogin.max.MISIS + _numes + FI_EA_SMS;
            break;

          // El uMetos no esta implementat perque es repetiex el codi amb R.Itowa i no s'utilitza

          case io.types.Monocable120:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MAM)
                if (_numes >= 1 && _numes <= io.analogin.max.MAM)
                  if (_numes == 1)
                    // Hem passat de 1 EA a 2 EA, però la numeració no és consequtiva. Per tant, hem de calcular d'una manera si és EA 1 o si és EA 2
                    // És com si fossin mòduls diferents amb 1 EA per mòdul
                    lval =
                      (_caixa - 1) * io.max.MAM * 1 /*nº màxim EA en aquest mòdul */ +
                      (_modul - 1) * 1 /*nº màxim EA en aquest mòdul */ +
                      _numes +
                      FI_EA_MET;
                  else if (_numes == 2)
                    lval =
                      (_caixa - 1) * io.max.MAM * 1 /*nº màxim EA en aquest mòdul */ +
                      (_modul - 1) * 1 /*nº màxim EA en aquest mòdul */ +
                      _numes -
                      1 /*només és 1 EA */ +
                      FI_EA_AR433;
            break;
          case io.types.Radio868_16:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MR16)
                if (_numes >= 1 && _numes <= io.analogin.max.MR16)
                  lval =
                    (_caixa - 1) * io.max.MR16 * io.analogin.max.MR16 +
                    (_modul - 1) * io.analogin.max.MR16 +
                    _numes +
                    FI_EA_AM120;
            break;
          case io.types.Radio24:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.AR24)
                if (_numes >= 1 && _numes <= io.analogin.max.MAR24)
                  lval =
                    (_caixa - 1) * io.max.MAR24 * io.analogin.max.MAR24 +
                    (_modul - 1) * io.analogin.max.MAR24 +
                    _numes +
                    FI_EA_AR16;
            break;
          case io.types.Radio433:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MAR433)
                if (_numes >= 1 && _numes <= io.analogin.max.MAR433)
                  lval =
                    (_caixa - 1) * MAX_MAR433 * io.analogin.max.MAR433 +
                    (_modul - 1) * io.analogin.max.MAR433 +
                    _numes +
                    FI_EA_AR24;
            break;
          case io.types.Davis:
            if (_caixa == 0)
              if (_modul == 0) if (_numes >= 1 && _numes <= io.analogin.max.DAVIS) lval = _numes + FI_EA_AM120_2;
            break;
        }
      }

      return lval;
    }
    function encodeDigitalOutput8(input, type, io) {
      //Digital Output
      if (input.length !== 8) {
        return -1;
      }

      var _tipus, _caixa, _modul, _numes, lval;

      _tipus = Number(input.substring(0, 2));
      _caixa = Number(input.substring(2, 3));
      _modul = Number(input.substring(3, 6));
      _numes = Number(input.substring(6, 8));
      if (type === UNITS.A_4500) {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0)
              if (_modul == 0) {
                if (_numes >= 1 && _numes <= FI_SD_BASE(type)) lval = _numes;
              } else if (_modul == 1) {
                // Aix� es que sortides > 2 digits
                if (100 + _numes <= FI_SD_BASE(type)) lval = 100 + _numes;
              }
            break;
          case io.types.AgroBeeL:
            if (
              0 < _caixa &&
              _caixa <= MAXAGROBEEL(type) &&
              0 < _modul &&
              _modul <= MAX_AGROBEEL &&
              0 < _numes &&
              _numes <= MAX_AGROBEEL_SOR_DIG
            )
              lval =
                (_caixa - 1) * MAX_AGROBEEL * MAX_AGROBEEL_SOR_DIG +
                (_modul - 1) * MAX_AGROBEEL_SOR_DIG +
                _numes +
                FI_SD_BASE(type);
            break;
          case io.types.ModBus:
            if (_caixa == 0 && 0 < _modul && _modul <= MAX_MODBUS && 0 < _numes && _numes <= MAX_MODBUS_SOR_DIG)
              lval = (_modul - 1) * MAX_MODBUS_SOR_DIG + _numes + FI_SD_AGROBEEL(type);
            break;
          case io.types.AMonocable120:
            if (
              0 < _caixa &&
              _caixa <= MAXEAM(type) &&
              0 < _modul &&
              _modul <= MAX_MAM &&
              0 < _numes &&
              _numes <= MAX_MAM_SOR_DIG
            )
              lval =
                (_caixa - 1) * MAX_MAM * MAX_MAM_SOR_DIG + (_modul - 1) * MAX_MAM_SOR_DIG + _numes + FI_SD_MODBUS(type);
            break;
          case io.types.ARadio433:
            if (
              0 < _caixa &&
              _caixa <= MAXEAR433(type) &&
              0 < _modul &&
              _modul <= MAX_MAR433 &&
              0 < _numes &&
              _numes <= MAX_MAR433_SOR_DIG
            )
              lval =
                (_caixa - 1) * MAX_MAR433 * MAX_MAR433_SOR_DIG +
                (_modul - 1) * MAX_MAR433_SOR_DIG +
                _numes +
                FI_SD_AM120_FUNC(type);
            break;
          case io.types.BaseExpansion:
            if (
              _caixa == 0 &&
              0 < _modul &&
              _modul <= MAX_EXPANSIOBASE &&
              0 < _numes &&
              _numes <= MAX_EXPANSIOBASE_SOR_DIG
            )
              lval = (_modul - 1) * MAX_EXPANSIOBASE_SOR_DIG + _numes + FI_SD_AR433_FUNC(type);
            break;
        }
      } else {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0) if (_modul == 0) if (_numes >= 1 && _numes <= FI_SD_BASE(type)) lval = _numes;
            break;
          case io.types.Expansio:
            if (_caixa == 0)
              if (_modul >= 1 && _modul <= io.max.ME)
                if (_numes >= 1 && _numes <= io.digitalout.max.ME)
                  lval = (_modul - 1) * io.digitalout.max.ME + _numes + FI_SD_BASE(type);
            break;
          case io.types.Radio868:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MR)
                //MAXCER(equip)if (_numes >= 1 && _numes <= io.digitalout.max.MR)
                lval =
                  (_caixa - 1) * io.max.MR * io.digitalout.max.MR +
                  (_modul - 1) * io.digitalout.max.MR +
                  _numes +
                  FI_SD_ME;
            break;
          case io.types.Monocable120:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MAM)
                if (_numes >= 1 && _numes <= io.digitalout.max.MAM)
                  lval =
                    (_caixa - 1) * io.max.MAM * io.digitalout.max.MAM +
                    (_modul - 1) * io.digitalout.max.MAM +
                    _numes +
                    FI_SD_RIT;
            break;
          case io.types.Radio868_16:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MR16)
                if (_numes >= 1 && _numes <= io.digitalout.max.MR16)
                  lval =
                    (_caixa - 1) * io.max.MR16 * io.digitalout.max.MR16 +
                    (_modul - 1) * io.digitalout.max.MR16 +
                    _numes +
                    FI_SD_AM120;
            break;
          case io.types.Radio24:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MAR24)
                if (_numes >= 1 && _numes <= io.digitalout.max.MAR24)
                  lval =
                    (_caixa - 1) * io.max.MAR24 * io.digitalout.max.MAR24 +
                    (_modul - 1) * io.digitalout.max.MAR24 +
                    _numes +
                    FI_SD_AR16;
            break;
          case io.types.Radio433:
            if (_caixa >= 1 && _caixa <= 1)
              if (_modul >= 1 && _modul <= io.max.MAR433)
                if (_numes >= 1 && _numes <= io.digitalout.max.MAR433)
                  lval =
                    (_caixa - 1) * MAX_MAR433 * io.digitalout.max.MAR433 +
                    (_modul - 1) * io.digitalout.max.MAR433 +
                    _numes +
                    FI_SD_AR24;
            break;
        }
      }
      return lval;
    }
    function encodeDigitalInput8(input, type, io) {
      //Digital Input
      if (input.length !== 8) {
        return -1;
      }

      var _tipus, _caixa, _modul, _numes, lval;

      _tipus = Number(input.substring(0, 2));
      _caixa = Number(input.substring(2, 3));
      _modul = Number(input.substring(3, 6));
      _numes = Number(input.substring(6, 8));
      if (type == UNITS.A_4500) {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0)
              if (_modul == 0) {
                if (_numes >= 1 && _numes <= FI_ED_BASE(type)) lval = _numes;
              } else if (_modul == 1) {
                // Aix� es que sortides > 2 digits
                if (100 + _numes <= FI_ED_BASE(type)) lval = 100 + _numes;
              }
            break;
          case io.types.Virtual:
            if (_caixa == 0 && _modul == 0 && _numes == 1) lval = 1 + FI_ED_BASE(type);
            break;
          case io.types.AgroBeeL:
            if (
              0 < _caixa &&
              _caixa <= MAXAGROBEEL(type) &&
              0 < _modul &&
              _modul <= MAX_AGROBEEL &&
              0 < _numes &&
              _numes <= MAX_AGROBEEL_ENT_DIG
            )
              lval =
                (_caixa - 1) * MAX_AGROBEEL * MAX_AGROBEEL_ENT_DIG +
                (_modul - 1) * MAX_AGROBEEL_ENT_DIG +
                _numes +
                FI_ED_VIRTUAL(type);
            break;
          case io.types.ModBus:
            if (_caixa == 0 && 0 < _modul && _modul <= MAX_MODBUS && 0 < _numes && _numes <= MAX_MODBUS_ENT_DIG)
              lval = (_modul - 1) * MAX_MODBUS_ENT_DIG + _numes + FI_ED_AGROBEEL(type);
            break;
          case io.types.AMonocable120:
            if (
              0 < _caixa &&
              _caixa <= MAXEAM(type) &&
              0 < _modul &&
              _modul <= MAX_MAM &&
              0 < _numes &&
              _numes <= MAX_MAM_ENT_DIG
            )
              lval =
                (_caixa - 1) * MAX_MAM * MAX_MAM_ENT_DIG + (_modul - 1) * MAX_MAM_ENT_DIG + _numes + FI_ED_MODBUS(type);
            break;
          case io.types.ARadio433:
            if (
              0 < _caixa &&
              _caixa <= MAXEAR433(type) &&
              0 < _modul &&
              _modul <= MAX_MAR433 &&
              0 < _numes &&
              _numes <= MAX_MAR433_ENT_DIG
            )
              lval =
                (_caixa - 1) * MAX_MAR433 * MAX_MAR433_ENT_DIG +
                (_modul - 1) * MAX_MAR433_ENT_DIG +
                _numes +
                FI_ED_AM120_FUNC(type);
            break;
          case io.types.BaseExpansion:
            if (
              _caixa == 0 &&
              0 < _modul &&
              _modul <= MAX_EXPANSIOBASE &&
              0 < _numes &&
              _numes <= MAX_EXPANSIOBASE_ENT_DIG
            )
              lval = (_modul - 1) * MAX_EXPANSIOBASE_ENT_DIG + _numes + FI_ED_AR433_FUNC(type);
            break;
        }
      } else {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0) if (_modul == 0) if (_numes >= 1 && _numes <= io.digitalin.BASE) lval = _numes;
            break;
          case io.types.Expansio:
            if (_caixa == 0)
              if (_modul >= 1 && _modul <= io.max.ME)
                if (_numes >= 1 && _numes <= io.digitalin.max.ME)
                  lval = (_modul - 1) * io.digitalin.max.ME + _numes + io.digitalin.BASE;
            break;
          case io.types.Radio868:
            if (_caixa >= 1 && _caixa <= io.max.CER)
              if (_modul >= 1 && _modul <= io.max.MR)
                if (_numes >= 1 && _numes <= io.digitalin.MR)
                  lval =
                    (_caixa - 1) * io.max.MR * io.digitalin.max.MR +
                    (_modul - 1) * io.digitalin.max.MR +
                    _numes +
                    io.digitalin.ME;
            break;
          case io.types.Monocable64:
            if (_caixa >= 1 && _caixa <= io.max.CEM)
              if (_modul >= 1 && _modul <= io.max.MM)
                if (_numes >= 1 && _numes <= io.digitalin.max.MM)
                  lval =
                    (_caixa - 1) * io.max.MM * io.digitalin.max.MM +
                    (_modul - 1) * io.digitalin.max.MM +
                    _numes +
                    io.digitalin.AR868;
            break;
          case io.types.Monocable120:
            if (_caixa >= 1 && _caixa <= io.max.EAM)
              if (_modul >= 1 && _modul <= io.max.MAM)
                if (_numes >= 1 && _numes <= io.digitalin.max.MAM)
                  lval =
                    (_caixa - 1) * io.max.MAM * io.digitalin.max.MAM +
                    (_modul - 1) * io.digitalin.max.MAM +
                    _numes +
                    io.digitalin.AM64;
            break;
          case io.types.Radio868_16:
            if (_caixa >= 1 && _caixa <= io.max.CER16)
              if (_modul >= 1 && _modul <= io.max.MR16)
                if (_numes >= 1 && _numes <= io.digitalin.max.MR16)
                  lval =
                    (_caixa - 1) * io.max.MR16 * io.digitalin.max.MR16 +
                    (_modul - 1) * io.digitalin.max.MR16 +
                    _numes +
                    io.digitalin.AM120;
            break;
          case io.types.Radio24:
            if (_caixa >= 1 && _caixa <= io.max.EAR24)
              if (_modul >= 1 && _modul <= io.max.MAR24)
                if (_numes >= 1 && _numes <= io.digitalin.max.MAR24)
                  lval =
                    (_caixa - 1) * io.max.MAR24 * io.digitalin.max.MAR24 +
                    (_modul - 1) * io.digitalin.max.MAR24 +
                    _numes +
                    io.digitalin.AR16;
            break;
          case io.types.Radio433:
            if (_caixa >= 1 && _caixa <= io.max.EAR433)
              if (_modul >= 1 && _modul <= io.max.MAR433)
                if (_numes >= 1 && _numes <= io.digitalin.max.MAR433)
                  lval =
                    (_caixa - 1) * io.max.MAR433 * io.digitalin.max.MAR433 +
                    (_modul - 1) * io.digitalin.max.MAR433 +
                    _numes +
                    io.digitalin.AR24;
            break;
        }
      }
      return lval;
    }

    function encodeOutputA8(output, type, io) {
      //Analog Output

      if (output.length !== 8) {
        return -1;
      }

      var _tipus, _caixa, _modul, _numes, lval;

      _tipus = Number(output.substring(0, 2));
      _caixa = Number(output.substring(2, 3));
      _modul = Number(output.substring(3, 6));
      _numes = Number(output.substring(6, 8));
      if (type == UNITS.A_4500) {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0 && _modul == 0 && _numes >= 1 && _numes <= FI_SA_BASE(type)) lval = _numes;
            break;
          case io.types.ModBus:
            if (_caixa == 0 && 0 < _modul && _modul <= MAX_MODBUS && 0 < _numes && _numes <= MAX_MODBUS_SOR_ANA)
              lval = (_modul - 1) * MAX_MODBUS_SOR_ANA + _numes + FI_SA_BASE(type);
            break;
          case io.types.BaseExpansion:
            if (
              _caixa == 0 &&
              0 < _modul &&
              _modul <= MAX_EXPANSIOBASE &&
              0 < _numes &&
              _numes <= MAX_EXPANSIOBASE_SOR_ANA
            )
              lval = (_modul - 1) * MAX_EXPANSIOBASE_SOR_ANA + _numes + FI_SA_MODBUS(type);
            break;
        }
      } else {
        switch (_tipus) {
          case io.types.Base:
            if (_caixa == 0)
              if (_modul == 0) {
                if (_numes >= 1 && _numes <= FI_SA_BASE(type)) lval = _numes;
              }
            break;
        }
      }

      return lval;
    }
  }
})();
