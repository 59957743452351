import { UNITS } from '../utils/units.enum';
import { A4500_SDI12_STATUS } from '../utils/sdi12-status.enum';
import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('modulesDetailController', modulesDetailController);

  modulesDetailController.$inject = ['$scope', '$state', 'commFactory'];

  function modulesDetailController($scope, $state, commFactory) {
    var vm = this;
    vm.linkBox;
    vm.getModuleError;
    vm.UNITS = UNITS;
    vm.linkBox = $state.params.box;
    vm.currentUnit = $state.params.unit;
    vm.saveActive = saveActive;
    vm.parseLastComm = parseLastComm;
    vm.parseLastCommDigital = parseLastCommDigital;
    vm.filterActiveAgrobee = filterActiveAgrobee;
    vm.filterActiveModbus = filterActiveModbus;
    vm.changeExternalModulesVisibility = changeExternalModulesVisibility;
    vm.checkIsModuleVisible = checkIsModuleVisible;
    vm.checkIsModuleVisibleSDI12 = checkIsModuleVisibleSDI12;
    vm.getTerrain = getTerrain;
    vm.checkShouldShowAlert = checkShouldShowAlert;
    vm.formatDensity = formatDensity;
    vm.activeModules = activeModules;
    vm.activeAll = 0;
    vm.getModuleError = getModuleError;
    vm.showAll = true;
    vm.communicationsFacade = getContainer().resolve('communicationsFacade');
    vm.destroy$ = new Subject();
    vm.expansion_modules_types = {
      0: 'C186_BASE_RELES',
      1: 'C186_BASE_LATCH_3F',
      2: 'C186_BASE_LATCH_2F',
      3: 'C186_BASE_LATCH_2F_INV',
    };

    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.unit !== null && $state.params.box !== null) {
        if (vm.linkBox.pk.type === 11) {
          loadExternalModBus();
        } else {
          loadExternalModules();
        }
      } else {
        $state.go('externalmodules');
      }
      if (vm.linkBox && vm.linkBox.pk.type === 10) {
        vm.communicationsFacade.modules$.pipe(takeUntil(vm.destroy$)).subscribe((res) => {
          vm.linkBox = {
            pk: {
              type: 10,
              id: res[0].linkBox,
              deviceId: vm.currentUnit.id,
            },
            externalModules: res.map((em) => ({ ...em, pk: { id: em.id } })),
            modeTx: null,
            networkCode: null,
          };
        });
      }
    }

    function loadExternalModules() {
      if (vm.currentUnit.type !== UNITS.A_4500) {
        commFactory.linkBoxEM(vm.currentUnit.id, vm.linkBox.pk.type).then((modules) => {
          if (modules[0]) {
            vm.linkBox = modules[0];
            checkLinkStates();
          }
        });
      } else {
        if (vm.linkBox.pk.type !== 10) {
          commFactory.linkBoxEM(vm.currentUnit.id, vm.linkBox.pk.type, vm.linkBox.pk.id).then((modules) => {
            if (modules[0]) {
              vm.linkBox = modules[0];
              copyExternalModules();
              checkLinkStates();
            }
          });
        } else {
          vm.linkBox.externalModules.forEach((module) => {
            module.xState = loadState(module.status);
            module.pk = { id: module.id, type: 10 };
            module.xMarker = module.marker;
          });
        }
      }
    }

    function loadState(status) {
      switch (status) {
        case A4500_SDI12_STATUS.NOT_CONFIGURED:
          return 0;
        case A4500_SDI12_STATUS.CLOSED:
          return 1;
        case A4500_SDI12_STATUS.OPEN:
          return 2;
        case A4500_SDI12_STATUS.ERROR:
          return 3;
      }
    }

    function loadExternalModBus() {
      commFactory.modBusExterno(vm.currentUnit.id).then((list) => {
        vm.externalModBus = vm.linkBox;

        vm.externalModBus.typeName = 'externModbus'; // used i18n name in order to translate it on the html
        vm.externalModBus.externalModules = list.plain();
        setLinkBoxState(vm.externalModBus);
      });
    }

    function copyExternalModules() {
      switch (vm.linkBox.pk.type) {
        case 1:
          vm.linkBox.externalModules = vm.linkBox.externalModulesMAM120;
          break;
        case 4:
          vm.linkBox.externalModules = vm.linkBox.externalModulesMAR433;
          break;
        case 6:
          vm.linkBox.externalModules = vm.linkBox.externalModulesME;
          break;
        case 9:
          vm.linkBox.externalModules = vm.linkBox.externalModulesLora;
          break;
      }
    }

    function sortExternalModules() {
      vm.linkBox.externalModules.sort((a, b) => a.pk.id - b.pk.id);
    }

    function changeExternalModulesVisibility($event, type) {
      if (type === 'communicating') {
        vm.areEMCommunicatingVisible = $event.detail.checked;
      }

      if (type === 'no communicating') {
        vm.areEMNoCommunicatingVisible = $event.detail.checked;
      }
      if (type === 'error') {
        vm.areEMErrorVisible = $event.detail.checked;
      }
      vm.showAll = !vm.areEMCommunicatingVisible && !vm.areEMNoCommunicatingVisible && !vm.areEMErrorVisible;
    }

    function checkIsModuleVisible(module) {
      if (vm.linkBox.pk.type === 2 || vm.linkBox.pk.type === 1) {
        if (!module.active) {
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        }
        if (module.active) {
          return module.xState ? vm.areEMCommunicatingVisible || vm.showAll : vm.areEMErrorVisible || vm.showAll;
        }
      }

      if (vm.getModuleError(module) || module?.xState === 3 || module?.xStatus === 3)
        return vm.areEMErrorVisible || vm.showAll;
      switch (module.xState) {
        case 0:
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        case 1:
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        case 2:
          return vm.areEMCommunicatingVisible || vm.showAll;
        case 3:
          return vm.areEMNoCommunicatingVisible || vm.showAll;
      }
      switch (module.xStatus) {
        case 0:
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        case 1:
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        case 2:
          return vm.areEMCommunicatingVisible || vm.showAll;
        case 3:
          return vm.areEMNoCommunicatingVisible || vm.showAll;
      }
    }

    function checkIsModuleVisibleSDI12(module) {
      switch (module.status) {
        case 'NOT_CONFIGURED':
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        case 'CLOSED':
          return vm.areEMNoCommunicatingVisible || vm.showAll;
        case 'OPEN':
          return vm.areEMCommunicatingVisible || vm.showAll;
        case 'ERROR':
          return vm.areEMErrorVisible || vm.showAll;
      }
    }

    function formatDensity(value) {
      if (value === null || value === undefined) return '';
      let strValue = value.toString();
      if (strValue.length === 1) {
        strValue = strValue + '00';
      } else if (strValue.length === 2) {
        strValue = strValue + '0';
      }
      return strValue.slice(0, -2) + ',' + strValue.slice(-2);
    }

    function getTerrain(module) {
      switch (module.model) {
        case 1:
          return [
            { id: 0, name: 'modules.mineral' },
            { id: 1, name: 'modules.mantillo' },
            { id: 2, name: 'modules.lana' },
            { id: 3, name: 'modules.perlita' },
          ];
        case 2:
        case 5:
          return [
            { id: 0, name: 'modules.mineral' },
            { id: 1, name: 'modules.lana' },
            { id: 2, name: 'modules.turba' },
            { id: 3, name: 'modules.coco' },
          ];
        case 3:
        case 4:
          return [
            { id: 0, name: 'modules.mineral' },
            { id: 1, name: 'modules.arenoso' },
            { id: 2, name: 'modules.arcilla' },
            { id: 3, name: 'modules.arenosomarga' },
            { id: 4, name: 'modules.marga' },
            { id: 5, name: 'modules.arcillamarga' },
          ];
        default:
          return [];
      }
    }

    function checkShouldShowAlert() {
      if (vm.showAll) return false;

      let hasEMCommunicatingSubmodules;
      let hasEMNoCommunicatingSubmodules;
      const hasEMErrorSubmodules = !!vm.linkBox.externalModules.find(
        (item) =>
          vm.getModuleError(item) ||
          item?.xState === 3 ||
          item?.xStatus === 3 ||
          ((vm.linkBox.pk.type === 2 || vm.linkBox.pk.type === 1) && item.active && !item.xState)
      );

      if (vm.linkBox.pk.type === 6) {
        hasEMCommunicatingSubmodules = !!vm.linkBox.externalModules.find((item) => item.xStatus === 2);
        hasEMNoCommunicatingSubmodules = !!vm.linkBox.externalModules.find((item) => item.xStatus !== 2);
      } else {
        hasEMCommunicatingSubmodules = !!vm.linkBox.externalModules.find((item) => item.xState === 2);
        hasEMNoCommunicatingSubmodules = !!vm.linkBox.externalModules.find((item) => item.xState !== 2);
      }

      if (vm.linkBox.pk.type === 2 || vm.linkBox.pk.type === 1) {
        hasEMCommunicatingSubmodules = !!vm.linkBox.externalModules.find((item) => item.active && item.xState);
        hasEMNoCommunicatingSubmodules = !!vm.linkBox.externalModules.find((item) => !item.active);
      }

      if (vm.areEMNoCommunicatingVisible && vm.areEMCommunicatingVisible && vm.areEMErrorVisible) {
        return !hasEMNoCommunicatingSubmodules && !hasEMCommunicatingSubmodules && hasEMErrorSubmodules;
      }

      if (vm.areEMCommunicatingVisible && !vm.areEMNoCommunicatingVisible && !vm.areEMErrorVisible) {
        return !hasEMCommunicatingSubmodules;
      }

      if (vm.areEMCommunicatingVisible && vm.areEMNoCommunicatingVisible && !vm.areEMErrorVisible) {
        return !hasEMCommunicatingSubmodules && !hasEMNoCommunicatingSubmodules;
      }

      if (vm.areEMCommunicatingVisible && !vm.areEMNoCommunicatingVisible && vm.areEMErrorVisible) {
        return !hasEMCommunicatingSubmodules && !hasEMErrorSubmodules;
      }

      if (vm.areEMNoCommunicatingVisible && !vm.areEMCommunicatingVisible && !vm.areEMErrorVisible) {
        return !hasEMNoCommunicatingSubmodules;
      }
      if (vm.areEMNoCommunicatingVisible && vm.areEMCommunicatingVisible && !vm.areEMErrorVisible) {
        return !hasEMNoCommunicatingSubmodules && !hasEMCommunicatingSubmodules;
      }
      if (vm.areEMNoCommunicatingVisible && !vm.areEMCommunicatingVisible && vm.areEMErrorVisible) {
        return !hasEMNoCommunicatingSubmodules && !hasEMErrorSubmodules;
      }

      if (vm.areEMErrorVisible && !vm.areEMNoCommunicatingVisible && !vm.areEMCommunicatingVisible) {
        return !hasEMErrorSubmodules;
      }

      if (vm.areEMErrorVisible && vm.areEMNoCommunicatingVisible && !vm.areEMCommunicatingVisible) {
        return !hasEMErrorSubmodules && !hasEMNoCommunicatingSubmodules;
      }
      if (vm.areEMErrorVisible && !vm.areEMNoCommunicatingVisible && vm.areEMCommunicatingVisible) {
        return !hasEMErrorSubmodules && !hasEMCommunicatingSubmodules;
      }

      return false;
    }

    function saveActive() {
      vm.linkBox.unittype = vm.currentUnit.type;
      commFactory.updateLinkBox(vm.currentUnit.id, vm.linkBox).then((resp) => {
        vm.linkBox = resp.plain();
        if (vm.currentUnit.type === UNITS.A_4500) {
          copyExternalModules();
          sortExternalModules();
        }
        vm.selectActive = false;
        checkLinkStates();
      });
    }

    function activeModules() {
      if (vm.activeAll === 1) {
        vm.linkBox.externalModules.forEach((module) => {
          module.active = 1;
        });
      } else {
        vm.linkBox.externalModules.forEach((module) => {
          module.active = 0;
        });
      }
    }

    function filterActiveAgrobee(module) {
      if (
        (module.model !== null && module.model !== 0 && module.pk.type === 9) ||
        (module.model !== null && module.pk.type === 8) ||
        module.pk.type === 10
      ) {
        switch (module.xState) {
          case 0:
            return false;
          case 1:
          case 2:
          case 3:
            return true;
          default:
            return false;
        }
      }
    }

    function filterActiveModbus() {
      return !vm.linkBox.externalModules.find((mod) => mod.xState !== 0);
    }

    function checkLinkStates() {
      let agrobee = vm.currentUnit.inoptions.agrobee;
      let agrobeel = vm.currentUnit.inoptions.agrobeel;
      let agrobeel1;
      let agrobeel2;
      if (vm.currentUnit.type === UNITS.A_4500) {
        if (vm.currentUnit.inoptions.agroBeeL1) {
          agrobeel1 = true;
        } else if (vm.currentUnit.inoptions.agroBeeL2) {
          agrobeel2 = true;
        }
      }
      let sdi12 = true; // Ja no es una opció, sempre será actiu.
      let box = vm.linkBox;
      switch (box.pk.type) {
        case 1:
          if (vm.currentUnit.inoptions.optionMonocable || vm.currentUnit.inoptions.am) {
            if (vm.currentUnit.type === UNITS.A_4500) {
              box.enabled = box.active;
              setAR433AM120State(box);
            } else {
              box.enabled = true;
              if (box.xCommunicate) {
                if (box.pk.type === 7) {
                  if (box.port !== null && box.port !== 0) {
                    box.state = 'comm';
                  } else {
                    box.state = 'noconf';
                  }
                } else {
                  box.state = 'comm';
                }
              } else if (box.active && !box.xCommunicate) {
                box.state = 'error';
              } else {
                box.state = 'nocomm';
              }
            }
          }
          break;
        case 2:
        case 3:
        case 4:
        case 7:
          if (vm.currentUnit.inoptions.optionRadio || vm.currentUnit.inoptions.ar) {
            if (vm.currentUnit.type === UNITS.A_4500) {
              box.enabled = box.active;
              setAR433AM120State(box);
            } else {
              box.enabled = true;
              if (box.xCommunicate) {
                if (box.pk.type === 7) {
                  if (box.port !== null && box.port !== 0) {
                    box.state = 'comm';
                  } else {
                    box.state = 'noconf';
                  }
                } else {
                  box.state = 'comm';
                }
              } else if (box.active && !box.xCommunicate) {
                box.state = 'error';
              } else {
                box.state = 'nocomm';
              }
            }
          }
          break;
        case 5:
        case 6:
          if (box.xCommunicate) {
            if (box.pk.type === 7) {
              if (box.port !== null && box.port !== 0) {
                box.state = 'comm';
              } else {
                box.state = 'noconf';
              }
            } else {
              box.state = 'comm';
            }
          } else if (box.active && !box.xCommunicate) {
            box.state = 'error';
          } else {
            box.state = 'nocomm';
          }
          // box.enabled = true;
          break;
        case 8:
          if (agrobee) {
            setLinkBoxState(box);
            box.enabled = true;
          }
          break;
        case 9:
          if (vm.currentUnit.type !== UNITS.A_4500) {
            if (agrobeel) {
              box.enabled = true;
              setLinkBoxState(box);
            }
            break;
          } else {
            if (agrobeel1 && box.pk.id === 1) {
              box.enabled = true;
              setLinkBoxState(box);
            } else if (agrobeel2 && box.pk.id === 2) {
              box.enabled = true;
              setLinkBoxState(box);
            }
          }
          break;
        case 10:
          if (sdi12) {
            box.enabled = true;
            box.state = 'comm';
          }
          break;
      }
    }

    function setAR433AM120State(linkBox) {
      switch (linkBox.xcomunica) {
        case 0:
          linkBox.state = 'noconf';
          break;
        case 1:
          linkBox.state = 'nocomm';
          break;
        case 2:
          linkBox.state = 'comm';
          break;
        case 3:
          linkBox.state = 'error';
          break;
        default:
          linkBox.state = 'noconf';
      }
    }

    function setLinkBoxState(linkBox) {
      switch (linkBox.xState) {
        case 0:
          vm.linkBox.state = 'noconf';
          break;
        case 1:
          vm.linkBox.state = 'nocomm';
          break;
        case 2:
          vm.linkBox.state = 'comm';
          break;
        case 3:
          vm.linkBox.state = 'error';
          break;
        default:
          vm.linkBox.state = 'noconf';
      }
    }

    function getModuleError(box) {
      return (box.xMarker || (box.xMarker === 0 && box.pk.type !== 9)) & (0x01 | 0x02);
    }

    function parseLastComm(value) {
      var limit = 16;

      // Not sure if conditional is correct or not (in another pk.types), for sure that pk.type === 2 limit must be 16
      // so we don't delete the conditional, left it here as a hint in case conditional is correct for another pk.type
      // if (vm.linkBox.pk.type === 2) {
      //   limit = 4;
      // }
      var s = [];
      for (var j = 0; j < limit; j++) s.push((value & (1 << j)) >> j);
      if (vm.linkBox.pk.type === 8 || vm.linkBox.pk.type === 9) {
        return _.reverse(s);
      } else {
        return s;
      }
    }

    function parseLastCommDigital(value, model) {
      let limit = 0;
      switch (model) {
        case 1:
        case 6:
        case 13:
          limit = 2;
          break;
        case 2:
          limit = 8;
          break;
        case 7:
          limit = 9;
          break;
        case 8:
          limit = 6;
          break;
      }
      let s = [];
      for (let j = 0; j < limit; j++) s.push((value & (1 << j)) >> j);
      return s;
    }

    $scope.$on('refresh', function () {
      if (vm.linkBox.pk.type === 10) {
        vm.communicationsFacade.loadModules(vm.currentUnit.id, 'sdi-12');
      }
      if (!vm.selectActive && vm.linkBox.pk.type !== 11) {
        loadExternalModules();
      }

      if (vm.linkBox.pk.type === 11) {
        loadExternalModBus();
      }
    });

    $scope.$on('saveEM', () => {
      saveActive();
    });

    $scope.$on('cancelEM', () => {
      vm.selectActive = !vm.selectActive;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$watch(
      () => vm.selectActive,
      () => {
        $scope.$emit('emActive', vm.selectActive);
      }
    );
  }
})();
